import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import { Button, Skeleton, Slide, SwipeableDrawer, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { crossUpColors, giftyColors, whatsproColors } from 'src/theme/colors';
import { FormFirstContact } from '../FormFirstContact';
import './styles.css'
import CrossUpLogo from 'src/assets/images/crossup/navbar/crossup.png';
import GiftyLogo from 'src/assets/images/gifty/navbar/gifty.png';
import WhatsPROLogo from 'src/assets/images/whatspro/navbar/whatspro.png';
import { useNavigate } from 'react-router-dom';
import { mapPlatformToInfo } from 'src/helpers/mapPlatformToData';


function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        className='back-to-top-arrow'
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const AppbarWrapper = styled(AppBar)(
  () => `
    padding: 0em 0.5em;
    display: flex;
    justify-content: center;
    box-shadow: none;
    background: #fff;
    position: sticky; 
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
  `
);

const ContainerButton = styled(Button)(
  () => `
    font-size: 1em;
    font-weight: 400;
    padding: 0.2em 1em;
    text-transform: none;
  `
);



export default function NavBar({screen = 'crossup', platform, validPlatform, ...props}) {
  const matchQuery = useMediaQuery('(min-width:650px)');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if(screen === 'whatspro'){
    if(validPlatform){
      window.location.href = mapPlatformToInfo[platform].url;
    } else{
      window.location.href = 'https://whatspro.flowy.website/welcome';
    }}
    else{
      setOpen(true);
    }
  }

  const [scroll, setScroll] = React.useState(0);
  const [ticking, setTicking] = React.useState(false);
  const { children } = props;
  const navigate = useNavigate();

  React.useEffect(() => {
    document.addEventListener('scroll', showHeader)
    return () => document.removeEventListener('scroll', showHeader)
  }, [scroll])

  const showHeader = () => {
    scroll > 500 ? setTicking(true) : setTicking(false);
    setScroll(window.pageYOffset)
  }
  const colors = screen === 'crossup' ? crossUpColors : ( screen === 'gifty' ? giftyColors : whatsproColors);
  return (
    <>
      <CssBaseline />
      {matchQuery
        ? <AppbarWrapper style={{display: !ticking && 'none'}}>
            <Toolbar sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 0,
              margin: 0,
            }}>
              {matchQuery &&
                <img
                  src={screen === 'crossup' ? CrossUpLogo : ( screen === 'gifty' ? GiftyLogo : WhatsPROLogo)}
                  alt="Woki"
                  width={100}
                  style={{ marginLeft: '1em', cursor: 'pointer' }}
                  className={screen === 'crossup' ? 'crossup-logo' : ( screen === 'gifty' ? 'gifty-logo' : 'whatspro-logo')}
                  onClick={() => navigate('/' + screen)}
                />}
              <Box
                display='flex'
                justifyContent='center'
                gap={3}
                alignContent='center'
              >
                {screen === 'whatspro' && <ContainerButton
                  className='navbar-button-cta'
                  variant='contained'
                  sx={{
                    '&:hover': {
                      background: `${colors.darkOrange}`
                    },
                    color: colors.white,
                    background: colors.darkOrange
                  }}
                  onClick={() => window.location.href = 'https://whatspro.flowy.website/login'}
                  fullWidth
                >
                  Iniciar sesión
                </ContainerButton>}
                <ContainerButton
                  className='navbar-button-cta'
                  variant='contained'
                  sx={{
                    '&:hover': {
                      background: `${colors.primaryDark}`
                    },
                    color: colors.white,
                    background: colors.primaryDark
                  }}
                  onClick={handleOpen}
                  fullWidth
                >
                  {screen === 'whatspro' ? 'Comenzar' : 'Hablar con un experto'}
                </ContainerButton>
              </Box>
            </Toolbar>
          </AppbarWrapper>
        : <Box
          display='flex'
          justifyContent='center'
          gap={3}
          alignContent='center'
          sx={{
            position: 'fixed',
            bottom: 0,
            background: '#f3f3f3',
            width: '100vw',
            padding: '1.25em 2em',
            borderRadius: '1em 1em 0em 0em',
            zIndex: '2000',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
          }}
        >
          <ContainerButton
            className='navbar-button-cta'
            variant='contained'
            sx={{
              '&:hover': {
                background: `${colors.darkOrange}`
              },
              fontSize: '1.4em',
              padding: '0.5em',
              borderRadius: '0.5em',
              fontWeight: '600',
              color: colors.white,
              background: colors.darkOrange
            }}
            onClick={handleOpen}
            fullWidth
          >
            {/* Hablar con un experto */}
            {/* {validPlatform ? 'Comenzar' : 'Hablar con un experto'} */}
            Comenzar
          </ContainerButton>
        </Box>}
      <Toolbar id="back-to-top-anchor" />
      <Box>
        {props.children}
        <FormFirstContact 
          open={open} 
          setOpen={setOpen}
          url={'https://calendly.com/flowysupport'}
          />
      </Box>
      <ScrollTop {...props}>
        <Fab color="secondary" size="medium" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
}