import React from 'react';
import { useMediaQuery } from "@material-ui/core";
import { Box, Typography } from "@mui/material";

import { colors } from "src/theme/colors";
import CarouselDesktop from './CarouselDesktop';
import { CarouselMobile } from './CarouselMobile';

export function Testimonials() {

  const isDesktop = useMediaQuery('(min-width:1024px)');

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign='center'
        marginTop='2em'
      >
        <Typography
          variant="h5"
          fontSize={isDesktop ? '22px' : '16px'}
          fontWeight={700}
          color={colors.gray1}
          gutterBottom
        >
          TESTIMONIOS
        </Typography>
        <Typography
          variant="h2"
          fontSize={isDesktop ? '50px' : '26px'}
          fontWeight={600}
          color={colors.primaryDark}
          paddingBottom={'1em'}
          sx={{ width: isDesktop ? '50%' : '90%' }}
        >
          La palabra de nuestros clientes
        </Typography>
      </Box>
      {
        isDesktop
          ? <CarouselDesktop />
          : <CarouselMobile />
      }
    </Box>

  )
}
