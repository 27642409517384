import React from 'react';

// CSS
import Styles from './SectionWithImageItems.module.css';

const SectionWithImageItems = ({ title1, title2, title3, text1, text2, text3, srcImage, sectionName, backgroundColor, color, classContainer }) => {
    
    return (
        <section 
            className={ classContainer === 'RightText' ? Styles.GeneralContainerRight : Styles.GeneralContainerLeft } 
            style={{
                backgroundColor: `${backgroundColor}`,
            }}
        >
            <div className={classContainer === 'RightText' ? Styles.TextSectionRight : Styles.TextSectionLeft} >
                <ul style={{marginBottom: '2rem'}}>
                    <li className={Styles.ItemsText} style={{color: `${color}`}}>
                        <span className={Styles.ItemsSpan}>{ title1 }: </span>  
                        { text1 }
                    </li>
                    <li className={Styles.ItemsText} style={{color: `${color}`}}>
                        <span className={Styles.ItemsSpan}>{ title2 }: </span> 
                        { text2 }
                    </li>
                    <li className={Styles.ItemsText} style={{color: `${color}`}}>
                        <span className={Styles.ItemsSpan}>{ title3 }: </span> 
                        { text3 }
                    </li>
                </ul>
            </div>
            <div className={ classContainer === 'RightText' ? Styles.LogoSectionLeft : Styles.LogoSectionRight }>
                <img src={srcImage} alt="Logo" className={Styles.LogoItem}/>
                <h1 className={Styles.TitleLogo} style={{color: `${color}`}}>{ sectionName }</h1>
            </div>
        </section>
    )
}

export default SectionWithImageItems;