import { Routes, Route } from 'react-router-dom';
import { Footer } from 'src/components/Footer';
// import { CrossUp } from 'src/page/CrossUp';
import { Gifty } from 'src/page/Gifty';
import { WhatsPRO } from 'src/page/WhatsPRO';
import Home from 'src/page/Perfit/Home';
import { Redirect } from '../page/Redirect/Redirect';



export const AppRouter = () => {

    return (
        <>
            <Routes>
                <Route path="/perfit" element={<Home />} />
                <Route path="/gifty" element={<Gifty />} />
                <Route path="/crossup" element={<Redirect url={'https://flowypartners.com'} />} />
                <Route path='/whatspro/:platform' element={<WhatsPRO />} />
                <Route path="/whatspro" element={<WhatsPRO />} />
                <Route path="/*" element={<Redirect url={'https://flowypartners.com'} />} />
            </Routes>
            <Footer /> 
        </>
    )
}