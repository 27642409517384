import React from 'react';

// Components
import SimpleSection from '../../components/SimpleSection/SimpleSection';

// Images
import ShoppingIcon from '../../assets/Icons/Shopping-outlined.png';
import Background1 from '../../assets/Background/Background1.png';
import CartIcon from '../../assets/Icons/Shopping-cart.png';
import Image1 from '../../assets/images/image1.png';
import Image2 from '../../assets/images/image2.png';
import Image3 from '../../assets/images/image3.png';
import Image4 from '../../assets/images/image4.png';

// Logos
import FlowyLogo from '../../assets/Icons/Logo_Flowy.png';
import PerfitLogo from '../../assets/Icons/Logo_Perfit.png';

// CSS
import Styles from './Home.module.css';
import SectionWithImageItems from '../../components/SectionWithImageItems/SectionWithImageItems';
import SectionWithNumber from '../../components/SectionWithNumber/SectionWithNumber';
import NavBar from 'src/components/NavBar';

const Home = () => {

    const title1 = <h1 className={Styles.Title1}>Creá automations inteligentes con la nueva integracion <span className={Styles.SpanTitle1}>Flowy + Perfit</span></h1>

    const text1 = <p className={Styles.TextNumber}>Tener los dos complementos de <span className={Styles.SpanP5}>Flowy CrossUP</span> y de <span className={Styles.SpanP5}>Perfit</span> email marketing, si aun no los tienes puedes instalarlos desde el app store de <span className={Styles.SpanP5}>Tiendanube</span></p>
    const text2 = <p className={Styles.TextNumber} style={{ color: 'white' }}>El siguiente paso, es ingresar a <span className={Styles.SpanP5}>Perfit</span> y e ir a la seccion de <span className={Styles.SpanP5}>automations</span></p>
    const text3 = <p className={Styles.TextNumber}>Luego debes hacer click en el botón <span className={Styles.SpanP5}>“Crear Automation”</span></p>
    const text4 = <p className={Styles.TextNumber} style={{ color: 'white' }}>Y por último seleccionar las opciones dentro de <span className={Styles.SpanP5}>“CrossSelling”</span> que dicen Tiendanube y Flowy. Puedes elegir la qué quieras, personalizarla con la imagen de tu marca y activarla en minutos </p>

    return (
        <NavBar screen='crossup'>
            <main>
                <SimpleSection
                    title={title1}
                    text={'Aumentá la efectividad de tus automations ofreciendo productos relacionados a medida de los gustos de cada cliente'}
                    colorTitle={'white'}
                    colorText={'white'}
                    srcImg={Background1}
                    text1={''}
                />
                <section className={Styles.LogosSection}>
                    <img src={FlowyLogo} alt="Logo" className={Styles.FlowyImg} />
                    <h1 className={Styles.Plus}>+</h1>
                    <img src={PerfitLogo} alt="Logo" className={Styles.PerfitImg} />
                </section>
                <section className={Styles.Section3Container}>
                    <h1 className={Styles.TitleSection3}>¿CÓMO FUNCIONA?</h1>
                    <p>Analizando el <span className={Styles.SpanText2}>historial de ventas</span> de tu tienda detectamos los productos que tus clientes suelen <span className={Styles.SpanText2}>llevar juntos</span> o que tengan <span className={Styles.SpanText2}>mayor afinidad.</span></p>
                    <p>Con esta informacion, en funcion de lo que cada cliente <span className={Styles.SpanText2}>compró</span> o <span className={Styles.SpanText2}>agrego al carrito</span> le ofrecemos un producto <span className={Styles.SpanText2}>relacionado</span> en el autmation.</p>
                </section>
                <section className={Styles.Section4Container}>
                    <h1>Además, filtramos las sugerencias de tal forma que:</h1>
                    <p>No se repitan productos entre los productos ofrecidos</p>
                    <p>No se ofrezcan productos que el cliente agrego al carrito o compró</p>
                    <p>No se ofrezcan productos que no tienen stock</p>
                </section>
                <section className={Styles.Section5Container}>
                    <h1>¿CUÁLES AUTOMATIONS DE PERFIT TIENEN <span className={Styles.SpanTitle5}>ESTE SISTEMA INTELIGENTE DE SUGERENCIAS?</span></h1>
                    <p>Actualmente contamos con <span className={Styles.SpanP5}>6 automations</span> que ya incluyen sugerencias mediante cross selling: 3 para recuperar <span className={Styles.SpanP5}>carritos abandonados</span> y 3 para <span className={Styles.SpanP5}>ventas completadas.</span></p>
                </section>
                <SectionWithImageItems
                    title1={'Carrito abandonado con cross selling'}
                    title2={'Carrito abandonado con Cross Selling y relaciones'}
                    title3={'Carrito abandonado con cross selling y cupon de descuento'}
                    text1={'Ofrecé hasta 8 productos relacionados con los productos que quedaron en el carrito abandonado.'}
                    text2={'Ofrecé hasta 12 productos relacionados con los productos que quedaron en el carrito abandonado.'}
                    text3={'Ofrecé hasta 8 productos relacionados con los productos que quedaron en el carrito y un cupon de descuento personalizable de unico uso.'}
                    srcImage={CartIcon}
                    sectionName={'CARRITO ABANDONADO'}
                    backgroundColor={'#2C3357'}
                    color={'white'}
                    classContainer={'RightText'}
                />
                <SectionWithImageItems
                    title1={'Compra finalizada con cross selling'}
                    title2={'Compra finalizada con cross selling y relaciones'}
                    title3={'Compra finalizada con cross selling y cupón de descuento'}
                    text1={'Ofrece hasta 8 productos relacionados con los productos que compró el cliente.'}
                    text2={'Ofrece hasta 12 productos relacionados con los productos que compró el cliente.'}
                    text3={'Ofrece hasta 8 productos relacionados con los productos que compró el cliente, e incluye un cupón de descuento personalizable de un único uso.'}
                    srcImage={ShoppingIcon}
                    sectionName={'COMPRA FINALIZADA'}
                    backgroundColor={'white'}
                    color={'#2C3357'}
                    classContainer={'LeftText'}
                />
                <section className={Styles.Section6Container}>
                    <h1>¿Te gustaría utilizar las automations de <span className={Styles.SpanTitle5}>Perfit</span> con todas las sugerencias de <span className={Styles.SpanTitle5}>CrossUp?</span></h1>
                    <p>Solo necesitas seguir los siguentes pasos:</p>
                </section>
                <section className={Styles.Section7Container}>
                    <div className={Styles.TextLeftContainer}>
                        <h1>1</h1>
                        <div style={{ textAlign: 'center' }}>{text1}</div>
                    </div>
                    <div className={Styles.ImageLeftContainer}>
                        <img src={Image1} alt='Img1' className={Styles.ImgSection7} />
                    </div>
                </section>
                <SectionWithNumber
                    sideNumber={'Right'}
                    text={text2}
                    number={2}
                    srcImage={Image2}
                    backgroundColor={'#2C3357'}
                />
                <SectionWithNumber
                    sideNumber={'Left'}
                    text={text3}
                    number={3}
                    srcImage={Image3}
                    backgroundColor={'white'}
                />
                <section className={Styles.Section8Container}>
                    <div className={Styles.TextRightContainer}>
                        <h1>4</h1>
                        <div style={{ textAlign: 'center' }}>{text4}</div>
                    </div>
                    <div className={Styles.ImageRightContainer}>
                        <img src={Image4} alt='Img1' />
                    </div>
                </section>
                <section className={Styles.Section9Container}>
                    <h1>Activar <span className={Styles.SpanP5}>Flowy CrossUP + Perfit</span> es muy fácil y lograrás conversiones INCREIBLES.</h1>
                    <p>En caso qué necesites ayuda, todo nuestro equipo de soporte estara listo y encantado de poder ayudarte.</p>
                    <a
                        href='tel: +5491171535520'
                        style={{
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            backgroundColor: '#2C3357',
                            padding: '.8rem 1.5rem',
                            textDecoration: 'none',
                            textTransform: 'none',
                            fontFamily: 'Poppins',
                            borderRadius: '10px',
                            fontSize: '.89rem',
                            marginTop: '1rem',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#2C3357',
                                color: 'white',
                            }
                        }}
                    >
                        Contactar equipo de soporte
                    </a>
                </section>
            </main>
        </NavBar>
    )
}

export default Home;
