import { Box, Grid, styled, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { whatsproColors } from 'src/theme/colors'
import Beneficio1 from 'src/assets/images/whatspro/beneficios/beneficios1.png'
import Beneficio2 from 'src/assets/images/whatspro/beneficios/beneficios2.png'
import Beneficio3 from 'src/assets/images/whatspro/beneficios/beneficios3.png'
import Beneficio4 from 'src/assets/images/whatspro/beneficios/beneficios4.png'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const colors = whatsproColors;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 3,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'gray',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#2A2742',
    },
}));

export const BeneficiosMobile = ({ beneficios, index, progress }) => {

    const isTablet = useMediaQuery('(min-width:768px)');


    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign='center'
            padding='2em 1em'
            gap={4}
            marginBottom='2em'
            sx={{
                width: '100vw',
                backgroundColor: colors.white,
                color: colors.primary,
                borderRadius: '20px',
            }}
        >
            <Typography
                variant="h5"
                fontSize='1em'
                fontWeight={800}
            >
                BENEFICIOS
            </Typography>
            <Typography
                variant="h3"
                fontSize='1.6em'
                fontWeight={700}
                maxWidth='100%'
            >
                Beneficios de <span style={{color: colors.secondary}}>WhatsPRO</span> para tu tienda online
            </Typography>
            <Box>
                {
                    beneficios.map((beneficio, idx) => (
                        <Box key={idx} paddingBottom={2}>
                            <Typography
                                variant="h4"
                                fontSize='1.3em'
                                fontWeight={600}
                                textAlign='center'
                                gutterBottom
                            >
                                {beneficios[idx].title}
                            </Typography>
                            <img
                                src={beneficios[idx].image}
                                alt="Paella dish"
                                style={{ borderRadius: '15px', alignSelf: 'center', objectFit: 'cover', width: '100%' }}
                                width='100%'
                            />
                            < Typography
                                variant="h5"
                                fontSize='1em'
                                fontWeight={500}
                                textAlign='justify'
                                paddingBottom={3}
                            >
                                {beneficios[idx].description}
                            </Typography>
                        </Box>
                    ))
                }
            </Box>
        </Box >
    );
}

export const BeneficiosDesktop = ({ beneficios, index, progress, setIndex, setProgress }) => {

    const handleClick = (idx) => {
        setProgress(0);
        setIndex(idx);
    }
    // console.log(beneficios, index, progress);
    return (
        <Box
            display='flex'
            sx={{
                width: '100vw',
                height: '100vh',
                minHeight: '100vh',
                backgroundColor: colors.white2,
                color: colors.primary,
                borderRadius: '20px',
            }}
        >
            <Box
                width={'55vw'}
                padding='3em'
                display='flex'
                flexDirection='column'
                gap={3}

            >
                <Typography
                    variant="h5"
                    fontSize='1.3em'
                    fontWeight={800}
                    sx={{ color: colors.gray1 }}
                >
                    BENEFICIOS
                </Typography>
                <Typography
                    variant="h3"
                    fontSize='2.5em'
                    fontWeight={700}
                    paddingBottom='1em'
                >
                    Beneficios de <span style={{color: colors.secondary}}>WhatsPRO</span> para tu tienda online
                </Typography>
                {
                    beneficios.map((beneficio, idx) => (
                        <Box
                            key={idx}
                            display='flex'
                            flexDirection='column'
                            gap={3}
                            sx={{ cursor: 'pointer' }}
                            onClick={(e) => handleClick(idx)}
                        >
                            <Typography
                                variant="h4"
                                fontSize='1.7em'
                                fontWeight={600}
                            >
                                {beneficios[idx].title}
                            </Typography>
                            {idx === index && <Typography
                                variant="h5"
                                fontSize='1.1em'
                                fontWeight={500}
                            >
                                {beneficios[idx].description}
                            </Typography>}
                            <Box sx={{ width: '100%' }}>
                                <BorderLinearProgress
                                    variant="determinate"
                                    value={idx === index ? progress : 0}
                                    sx={{ width: '40%' }}
                                />
                            </Box>
                        </Box>
                    ))
                }
            </Box>
            <img
                src={beneficios[index].image}
                alt="Paella dish"
            // style={{ height: 'auto',}}
            />
        </Box>
    )
}

export const Beneficios = ({ active }) => {

    const isDesktop = useMediaQuery('(min-width:1024px)');

    const beneficios = [
        {
            title: 'Respondé consultas 24/7',
            description: 'WhatsPRO podrá responder a toda hora preguntas frecuentes automáticamente sin demoras y sin que tu cliente salga de la tienda',
            image: Beneficio1,
        },
        {
            title: 'Contá con múltiples asesores',
            description: 'Podrás cargar distintos números de teléfono con sus horarios de atención para que se deriven las consultas al contacto de Whatsapp que quieras.',
            image: Beneficio2,
        },
        {
            title: 'Configurá tus preguntas frecuentes',
            description: 'Armá las preguntas mas frecuentes de tu tienda con sus respectivas respuestas para que tus clientes tengan respuesta inmediata.',
            image: Beneficio3,
        },
        {
            title: 'Optimizá tu comunicación',
            description: 'Un sistema propio de atención con múltiples maneras de contacto mejora la conversión de todas las tiendas online.',
            image: Beneficio4,
        }
    ]
    const [index, setIndex] = React.useState(0);
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    setIndex((oldIndex) => {
                        if (oldIndex === beneficios.length - 1) {
                            return 0;
                        } else {
                            return oldIndex + 1;
                        }
                    })
                    return 0;
                }
                const diff = 3;
                return Math.min(oldProgress + diff, 100);
            });
        }, 200);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <>
            {
                isDesktop
                    ? <BeneficiosDesktop
                        beneficios={beneficios}
                        index={index}
                        progress={progress}
                        setIndex={setIndex}
                        setProgress={setProgress}
                    />
                    : <BeneficiosMobile
                        beneficios={beneficios}
                        index={index}
                        progress={progress}
                    />
            }
        </>
    )
}
