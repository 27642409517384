import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { giftyColors } from 'src/theme/colors';
import Carousel from 'react-multi-carousel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ExperienciaCard } from './ExperienciaCard';
import Experiencia1 from 'src/assets/images/gifty/experiencias/experiencias1.png';
import Experiencia2 from 'src/assets/images/gifty/experiencias/experiencias2.png';
import Experiencia3 from 'src/assets/images/gifty/experiencias/experiencias3.png';
import Experiencia4 from 'src/assets/images/gifty/experiencias/experiencias4.png';

const colors = giftyColors;

const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
    const { currentSlide, totalItems } = carouselState;
    const isDesktop = useMediaQuery('(min-width:1024px)');
    return (
      <div className="custom-button-group arrow-experiencies">
        {currentSlide !== 0
          ? <ArrowBackIcon onClick={() => previous()} fontSize='large' sx={{ cursor: 'pointer', fontSize: isDesktop && '3em', color: colors.primary }} />
          : <ArrowBackIcon onClick={() => previous()} fontSize='large' sx={{ color: colors.gray1, fontSize: isDesktop && '3em' }} />
        }
        {currentSlide !== totalItems - 1
          ? <ArrowForwardIcon onClick={() => next()} fontSize='large' sx={{ cursor: 'pointer', fontSize: isDesktop && '3em', color: colors.primary }} />
          : <ArrowForwardIcon onClick={() => next()} fontSize='large' sx={{ color: colors.gray1, fontSize: isDesktop && '3em' }} />
        }
      </div>
    );
  };

export const PorqueElegirnos = () => {

    const isDesktop = useMediaQuery('(min-width:1024px)');

    return (
        <Box marginBottom={'2em'} marginTop={'3em'}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign='center'
                gap={2}
            >
                <Typography
                    variant="h5"
                    fontSize={isDesktop ? '22px' : '16px'}
                    fontWeight={700}
                    color={colors.gray1}
                    gutterBottom
                >
                    POR QUÉ ELEGIRNOS
                </Typography>
                <Typography
                    variant="h2"
                    fontSize={isDesktop ? '50px' : '26px'}
                    fontWeight={600}
                    color={colors.primaryDark}
                    paddingBottom={'1em'}
                    width={isDesktop ? '100%' : '90%'}
                >
                    Llevá tu tienda al siguiente nivel
                </Typography>
            </Box>
            <div
                style={{
                    position: 'relative',
                    marginBottom: '2em',
                    // marginLeft: isDesktop && '3em',
                    padding: isDesktop ? '0em 0vw 50px 0vw' : '0em 0em 50px 0em',
                    width: '100%',
                }}
            >
                <Carousel
                    arrows={false}
                    centerMode={false}
                    autoPlaySpeed={70000000}
                    shouldResetAutoplay={false}
                    partialVisible={true}
                    customButtonGroup={<CustomButtonGroup />}
                    dotListClass="dot-experiencies"
                    draggable={!isDesktop}
                    focusOnSelect={isDesktop}
                    infinite={true}
                    // keyBoardControl
                    minimumTouchDrag={5}
                    renderButtonGroupOutside={true}
                    renderDotsOutside={true}
                    className="carousel-experiencies"
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1150
                            },
                            items: 3,
                            partialVisibilityGutter: 20
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1,
                            partialVisibilityGutter: 50
                        },
                        tablet: {
                            breakpoint: {
                                max: 1150,
                                min: 640
                            },
                            items: 2,
                            partialVisibilityGutter: 20
                        }
                    }}
                    showDots={true}
                    slidesToSlide={1}
                    swipeable={!isDesktop}
                >
                    <ExperienciaCard
                        imagen={Experiencia1}
                    />
                    <ExperienciaCard
                        imagen={Experiencia2}
                    />
                    <ExperienciaCard
                        imagen={Experiencia3}
                    /> 
                    <ExperienciaCard
                        imagen={Experiencia4}
                    /> 
                </Carousel>
            </div>
        </Box>
    )
}
