import * as React from 'react';
import Modal from '@mui/material/Modal';
import { PopupModal } from 'react-calendly';
import { gtag_report_conversion } from 'src/helpers/gtag';




export const FormFirstContact = ({ open = true, setOpen, url = 'https://calendly.com/flowysupport' }) => {
    const handleClose = () => {setOpen(false)}

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 2000 }}

            >
                <PopupModal
                    url={url}
                    onModalClose={handleClose}
                    open={open}
                    rootElement={document.getElementById("root")}
                />
            </Modal>
        </div>
    )
}


