import Tiendanube from 'src/assets/images/crossup/tiendanube.png';
import MercadoShops from 'src/assets/images/crossup/mercadoshops.png';

export const mapPlatformToInfo = {
    tiendanube: {
        url: 'https://www.tiendanube.com/apps/3905/authorize?state=csrf-code',
        img: Tiendanube,
        name: 'Tiendanube',
    },
    mercadoshops: {
        url: 'https://auth.mercadolibre.com.ar/authorization?response_type=code&client_id=4667354953492772',
        img: MercadoShops,
        name: 'MercadoShops',
    },
    particular: {
        url: 'https://whatspro.flowy.website/welcome',
        img: '',
        name: 'Particular',
    }
}
