export const colors = {
    primary: '#0B4762',
    secondary: '#1AAFDE',
    primaryDark: '#041C32',
    light: '#D6D6D6',
    dark: '#041C32',
    orange: '#F97F59',
    gray1: '#B9B9B9',
    gray2: '#EAEAEA',
    gray3: '#E7E7E7',
    success : '#35C18F',
    error: '#EB252E',
    warning: '#EEB115',
    white: '#FFF',
    darkOrange: '#f06439',
    black: '#4D4D4D',
}

export const crossUpColors = {
    primary: '#2A2742',
    secondary: '#1078FF',
    primaryDark: '#041C32',
    light: '#2A2742',
    dark: '#041C32',
    orange: 'linear-gradient(90deg, #B81EC5 0%, #1078FF 100%)',
    gray1: '#B9B9B9',
    gray2: '#EAEAEA',
    gray3: '#E7E7E7',
    success : '#35C18F',
    error: '#EB252E',
    warning: '#EEB115',
    white: '#FFF',
    darkOrange: 'linear-gradient(90deg, #B81EC5 0%, #1078FF 100%)',
    black: '#4D4D4D',
    help: '#B81EC5'
}

export const giftyColors = {
    primary: '#2A2742',
    secondary: '#FF5999',
    primaryDark: '#041C32',
    light: '#2A2742',
    dark: '#041C32',
    orange: '#FF5999',
    gray1: '#B9B9B9',
    gray2: '#EAEAEA',
    gray3: '#E7E7E7',
    success : '#35C18F',
    error: '#EB252E',
    warning: '#EEB115',
    white: '#f5f5f5',
    darkOrange: '#FF5999',
    black: '#4D4D4D',
    help: '#FF5999',
    white2: '#fff',
}

export const whatsproColors = {
    primary: '#2A2742',
    secondary: '#2ED588',
    primaryDark: '#041C32',
    light: '#2ED588',
    dark: '#041C32',
    orange: '#2ED588',
    gray1: '#B9B9B9',
    gray2: '#EAEAEA',
    gray3: '#E7E7E7',
    success : '#35C18F',
    error: '#EB252E',
    warning: '#EEB115',
    white: '#f5f5f5',
    darkOrange: '#2ED588',
    black: '#4D4D4D',
    help: '#2ED588',
    white2: '#fff',

}
