import { Box, Grid, styled, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import FlowyLogo from 'src/assets/images/crossup/logo.png'
import { crossUpColors, whatsproColors } from 'src/theme/colors'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

let colors = crossUpColors;

const FooterLink = styled('a')(
    () => `
    text-decoration: none;
    color: ${colors.white};
    font-size: 1.2em;
    font-weight: 400;
    cursor: pointer;
    &:hover {
        color: ${colors.orange};
    }
    `
)

const FooterLinkRR = styled(Link)(
    () => `
    text-decoration: none;
    color: ${colors.white};
    font-size: 1.2em;
    font-weight: 400;
    cursor: pointer;
    &:hover {
        color: ${colors.orange};
    }
    `
)
export const Footer = () => {

    //get current url
    const currentUrl = window.location.href;
    //check if pathname is /whatspro
    const isWhatspro = currentUrl.includes('/whatspro');
    colors = isWhatspro ? whatsproColors : crossUpColors;

    const isDesktop = useMediaQuery('(min-width:1024px)');

    return (
        <footer
            style={{
                background: isWhatspro ? colors.secondary : colors.primary,
                color: colors.white,
                padding: '3em 4em 10em 4em',
                // minHeight: '100vh',
            }}
        >
            <Grid
                container
                spacing={3}
                alignItems={!isDesktop ? 'center' : "flex-start"}
                textAlign={!isDesktop ? 'center' : "flex-start"}
            >
                <Grid item xs={12} sm={6} md={3}>
                    <Link
                        // style={{ position: 'relative', top: '-1em' }}
                        to="/"
                    >
                        <img src={FlowyLogo} alt="flowy" width='200' />
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography
                        variant="h6"
                        fontSize={'1.5em'}
                        fontWeight={600}
                        paddingBottom={2}
                    >
                        EMPRESA
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        alignItems={!isDesktop ? 'center' : "flex-start"}
                    >
                        <FooterLink
                            href='https://www.linkedin.com/company/flowyapp/'
                            target='_blank'
                            rel='noopener noreferrer'
                            sx={{
                                '&:hover': {
                                    color: isWhatspro ? colors.primaryDark : colors.orange
                                }
                            }}
                        >
                            Sobre flowy
                        </FooterLink>
                        <FooterLink
                            href='https://www.linkedin.com/company/flowyapp/jobs/'
                            target='_blank'
                            rel='noopener noreferrer'
                            sx={{
                                '&:hover': {
                                    color: isWhatspro ? whatsproColors.primaryDark : crossUpColors.orange
                                }
                            }}
                        >
                            Trabajá en flowy
                        </FooterLink>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography
                        variant="h6"
                        fontSize={'1.5em'}
                        fontWeight={600}
                        paddingBottom={2}
                    >
                        SOPORTE
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        alignItems={!isDesktop ? 'center' : "flex-start"}
                    >
                        <FooterLink
                            href='https://walink.co/bdfffd'
                            target='_blank'
                            rel='noopener noreferrer'
                            sx={{
                                '&:hover': {
                                    color: isWhatspro ? whatsproColors.primaryDark : crossUpColors.orange
                                }
                            }}
                        >
                            Contáctanos
                        </FooterLink>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography
                        variant="h6"
                        fontSize={'1.5em'}
                        fontWeight={600}
                        paddingBottom={2}
                    >
                        SEGUINOS
                    </Typography>
                    <Box
                        display="flex"
                        gap={2}
                        alignItems='center'
                        justifyContent={isDesktop ? 'flex-start' : 'center'}
                    >
                        {/* <FooterLink
                            href='https://www.facebook.com/wokioficial'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <FacebookIcon fontSize='large' />
                        </FooterLink>
                        <FooterLink
                            href='https://twitter.com/wokioficial'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <TwitterIcon fontSize='large' />
                        </FooterLink> */}
                        <FooterLink
                            href='https://www.instagram.com/flowyoficial/'
                            target='_blank'
                            rel='noopener noreferrer'
                            sx={{
                                '& :hover': {
                                    color: isWhatspro ? whatsproColors.primaryDark : crossUpColors.orange
                                }
                            }}
                        >
                            <InstagramIcon fontSize='large' />
                        </FooterLink>
                        <FooterLink
                            href='https://www.linkedin.com/company/flowyapp'
                            target='_blank'
                            rel='noopener noreferrer'
                            sx={{
                                '& :hover': {
                                    color: isWhatspro ? whatsproColors.primaryDark : crossUpColors.orange
                                }
                            }}
                        >
                            <LinkedInIcon fontSize='large' />
                        </FooterLink>
                    </Box>
                </Grid>
            </Grid>
            <Box
                marginTop={isDesktop ? 10 : 5}
                display='flex'
                alignItems='center'
                justifyContent='center'
                flexDirection='column'
                gap={2}
            >
                <Typography
                    variant='h6'
                    fontSize='18px'
                    fontWeight={400}
                >
                    2021 - {new Date().getFullYear()} © BOOST YOUR SALES S.A. - Todos los derechos reservados.
                </Typography>
            </Box>
        </footer>
    )
}
