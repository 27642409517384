import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { colors } from 'src/theme/colors'

export const ExperienciaCard = ({imagen, title, description}) => {

    const isDesktop = useMediaQuery('(min-width:1024px');

  return (
    <Box
        position='relative'
        padding={'0em 0.5em'}
        sx={{cursor: 'pointer'}}
    >
        <img 
            src={imagen} 
            alt={title} 
            width={isDesktop ? '100%' : '100%'}
            height={isDesktop ? 'auto' : '100%'}
            style={{ borderRadius: '10px', alignSelf: 'center', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', background: 'white' }}
        />
        <Box
            position='absolute'
            top={isDesktop ? '2.3em' : '1em'}
            left={isDesktop ? '3em' : '1em'}
            width={isDesktop ? 'calc(100% - 2em)' : 'calc(100% - 1em)'}
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            justifyContent='center'
            gap={1}

        >
            <Typography
                variant='h3'
                fontSize={isDesktop ? '1.5em' : '1.3em'}
                fontWeight={600}
                color={colors.primaryDark}
            >
                {title}
            </Typography>
            <Typography
                variant='h5'
                fontSize={isDesktop ? '1.1em' : '0.9em'}
                fontWeight={500}
            >
                {description}
            </Typography>
        </Box>
    </Box>
  )
}
