import { Label } from '@mui/icons-material';
import {
    Box,
    Button,
    Container,
    Grid,
    Skeleton,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { giftyColors } from 'src/theme/colors';
import './styles.css';
import TypewriterComponent from 'typewriter-effect';
import { useEffect, useState } from 'react';
import { FormFirstContact } from 'src/components/FormFirstContact';
import Loading from 'react-fullscreen-loading';
import HeroImg from 'src/assets/images/gifty/hero/g-hero.png'
import Tiendanube from 'src/assets/images/crossup/tiendanube.png';

const colors = giftyColors;

const ContainerButton = styled(Button)(
    () => `
    font-size: 1.7em;
    font-weight: 500;
    padding: 0.4em 0.7em;
    color: ${colors.white};
    background: ${colors.orange};
    text-transform: none;
    border-radius: 0.7em;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  `
);

const getCorrectFontSize = (queryHeigth, queryWidth) => {
    if (queryHeigth && queryWidth) {
        return '2.6em'
    } else if (queryHeigth) {
        return '2.4em'
    } else if (queryWidth) {
        return '2.2em'
    }
    return '1.8em';
}

const styleRelative = {
    position: 'relative',
    left: '0.5em',
    zIndex: 3,
}

function Hero() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => { setOpen(true); }
    const matchQuery = useMediaQuery('(min-width:650px)');
    const matchQueryMD = useMediaQuery('(min-width:900px)');
    const matchQueryLG = useMediaQuery('(min-width:1200px)');
    const matchQueryHeight = useMediaQuery('(min-height: 800px)');
    const matchQueryMobileWidth = useMediaQuery('(min-width: 400px)');


    const [loaded, setLoaded] = useState(true); //todo


    return (
        <>
            {!loaded && <Loading loading background="#fff" loaderColor="#3498db" />}
            <Container
                maxWidth="lg"
                className='hero-container'
            >
                <Grid
                    spacing={{ xs: 2, md: 0 }}
                    justifyContent="space-between"
                    alignItems={matchQueryMD ? 'center' : 'flex-start'}
                    container
                    sx={{ padding: 0, height: matchQueryMD ? '100vh' : '' }}
                >
                    <Grid item xs={12} lg={6} order={{ xs: 2, lg: 1 }} className='hero-title'>
                        <Box
                            display='flex'
                            flexDirection='column'
                            gap={matchQueryMD ? 10 : 2}
                            alignItems={matchQueryMD ? 'flex-start' : 'center'}
                            padding={'0em 1em 3em 1em'}
                            position={matchQueryLG && 'relative'}
                        >
                            <Typography
                                variant="h4"
                                fontSize={matchQueryMD ? '2em' : '1.2em'}
                                color="textPrimary"
                                fontWeight={500}
                                textAlign={matchQueryMD ? 'left' : 'center'}
                                sx={{display: 'flex', alignItems: 'center', gap: matchQueryMD ? '0.5em' : '0.2em', color: colors.light}}
                            >
                                Partners oficiales de
                                <img
                                    src={Tiendanube}
                                    alt="TiendaNube"
                                    style={{ width: '7em'}}
                                />
                            </Typography>
                            <Typography
                                variant="h1"
                                fontSize={matchQueryMD ? '3em' : '2em'}
                                fontWeight={600}
                                textAlign={matchQueryMD ? 'left' : 'center'}
                                sx={{color: colors.primary, lineHeight: matchQueryMD ? '1.3em' : '1.2em', letterSpacing: '1px'}}
                            >
                                Una <span style={{color: colors.secondary}}>Gift-Card</span> es mucho más que otro producto en tu tienda
                            </Typography>

                            {matchQuery && <ContainerButton
                                sx={{
                                    width: matchQueryMD ? '15em' : '90%', '&:hover': {
                                        background: `${colors.darkOrange}`
                                    },
                                    zIndex: 3,
                                    // zIndex: matchQueryLG && styleRelative.zIndex
                                }}
                                onClick={handleOpen}
                            >
                                Pactar una demo
                            </ContainerButton>}
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }}
                        sx={{ paddingLeft: '0 !important', paddingRight: '0 !important' }}
                    >
                        <Box
                            className='hero-grid-item'
                        >
                            <div className='gradient-overlay2'>
                                <img
                                    src={HeroImg}
                                    alt='Hero PNG'
                                    style={{
                                        width: '100%',
                                        height: matchQueryLG ? '100vh' : '50vh',
                                        objectFit: 'cover',
                                    }}
                                />
                            </div>
                            {/* : <Skeleton width='100vw' height='400px'/>} */}
                        </Box>
                    </Grid>
                    <FormFirstContact open={open} setOpen={setOpen} url={'https://calendly.com/flowysupport'}/>
                </Grid>
            </Container>
        </>
    );
}

export default Hero;
