import React from 'react';

// CSS
import Styles from './SectionWithNumber.module.css';

const SectionWithNumber = ({ sideNumber, text, number, srcImage, backgroundColor }) => {
    return (
        <section 
            className={ sideNumber === 'Left' ? Styles.NumberLeftContainer : Styles.NumberRightContainer}
            style={{
                backgroundColor: backgroundColor
            }}
        >
            <div className={sideNumber === 'Left' ? Styles.TextLeftContainer : Styles.TextRightContainer}>
                <h1>{ number }</h1>
                <div style={{textAlign: 'center'}}>{ text }</div>
            </div>
            <div className={sideNumber === 'Left' ? Styles.ImageLeftContainer : Styles.ImageRightContainer}>
                <img src={srcImage} alt={`Img${number}`} />
            </div>
        </section>
    )
}

export default SectionWithNumber;