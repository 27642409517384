import { Button, Box, Divider, styled, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { giftyColors } from 'src/theme/colors'

const colors = giftyColors;

const ContainerButton = styled(Button)(
    () => `
    font-size: 1.3em;
    font-weight: 500;
    padding: 0.4em 0.7em;
    color: ${colors.white};
    background: ${colors.orange};
    text-transform: none;
    border-radius: 0.7em;
    width: 90vw;
    max-width: 25em;
  `
);

const EtapaCrecimiento = ({ title, description }) => {

    const isDesktop = useMediaQuery('(min-width:1024px)');

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent='space-evenly'
            alignItems={'center'}
            textAlign={'center'}
            padding={1}
            sx={{
                borderRadius: '15px',
                background: '#F1F1F1',
                height: isDesktop ? '20em' : '15em',
                width: '100%',
                color: colors.primaryDark,
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                maxWidth: '25em'
            }}
        >
            <Typography
                variant='h2'
                fontSize={isDesktop ? '30px' : '25px'}
                fontWeight={600}
            >
                {title}
            </Typography>
            <Divider variant='middle' sx={{ alignSelf: 'center', width: '20%', border: `1px solid ${colors.primaryDark}` }} />
            <Typography
                variant='h4'
                fontSize={isDesktop ? '17px' : '17px'}
                fontWeight={500}
                width={'90%'}
            >
                {description}
            </Typography>
        </Box>
    )
}

export const Contacto = () => {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                background: colors.primary,
                color: colors.white,
                padding: '2em',
                paddingBottom: '3em'
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems={!isDesktop && "center"}
                // justifyContent="center"
                textAlign='center'
                gap={isDesktop ? 3 : 2}
            >
                <Typography
                    variant="h5"
                    fontSize={isDesktop ? '22px' : '16px'}
                    fontWeight={700}
                    color={colors.gray1}
                    gutterBottom
                >
                    EMPEZÁ YA
                </Typography>
                <Typography
                    variant="h2"
                    fontSize={isDesktop ? '50px' : '30px'}
                    fontWeight={600}
                    color={colors.white}
                    paddingBottom={'0.5em'}
                >
                    Suscribite y potenciá tu Tienda
                </Typography>
                <Typography
                    variant="h2"
                    fontSize={isDesktop ? '20px' : '18px'}
                    fontWeight={400}
                    color={colors.white}
                    paddingBottom={'1em'}
                >
                    Con <span style={{color: colors.secondary}}>Gifty</span> te acompañamos en todas tus etapas de crecimiento.
                </Typography>
                <Box
                    display='flex'
                    flexDirection={isDesktop ? 'row' : 'column'}
                    justifyContent='center'
                    gap={isDesktop ? 4 : 2}
                >
                    <EtapaCrecimiento
                        title='Emprendimientos'
                        description='Tomá el impulso que necesita tu emprendimiento para llegar al siguiente nivel'
                    />
                    <EtapaCrecimiento
                        title='Mediana empresa'
                        description='No pares de crecer dandole a tus clientes una experiencia de compra diferencial'
                    />
                    <EtapaCrecimiento
                        title='Gran empresa'
                        description='Ofrecé GiftCards de la misma manera que empresas como Starbucks, McDonalds y Walmart hacen'
                    />
                </Box>
                <ContainerButton
                    variant='contained'
                    fullWidth={!isDesktop}
                    sx={{
                        '&:hover': {
                            background: `${colors.darkOrange}`
                        },
                        marginTop: '1em',
                        alignSelf: 'center'
                    }}
                    onClick={() => window.location.href = 'https://www.tiendanube.com/tienda-aplicaciones-nube/flowy-gifty'}
                >
                    Comenzar con Gifty
                </ContainerButton>
            </Box>
        </Box>
    )
}
