import React from 'react';

// CSS
import Styles from './SimpleSection.module.css';

const SimpleSection = ({ srcImg, title, text, text1, colorTitle, colorText, height }) => {
    return (
        <section 
            className={Styles.SimpleContainer} 
            style={{backgroundImage: `url(${srcImg})`}}
        >
            <div style={{color: `${colorTitle}`}}>{ title }</div>
            <p className={Styles.Text} style={{color: `${colorText}`}}>{ text }</p>
            {
                text1 !== '' ?
                    <p className={Styles.Text} style={{color: `${colorText}`}}>{ text }</p>
                :
                    null
            }
        </section>
    )
}

export default SimpleSection;