import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { whatsproColors } from 'src/theme/colors';
import Costo1 from 'src/assets/images/whatspro/costo/costo1.png'
import heart from 'src/assets/images/whatspro/costo/heart.png'
import money from 'src/assets/images/whatspro/costo/money.png'
import people from 'src/assets/images/whatspro/costo/people.png'

const colors = whatsproColors;

const CostoFeature = ({ title, description, icon }) => {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent='flex-start'
            textAlign='left'
            gap={2}
        >
            <Box display='flex' gap={1} alignItems='center'>
                <img src={icon} alt="heart" />
                <Typography
                    variant="h5"
                    fontSize={isDesktop ? '1.5em' : '1.2em'}
                    fontWeight={600}
                    sx={{ color: colors.primary }}
                >
                    {title}
                </Typography>
            </Box>
            <Typography
                variant="h5"
                fontSize={isDesktop ? '1.2em' : '1em'}
                fontWeight={400}
                sx={{ color: colors.primary }}
            >
                {description}
            </Typography>
        </Box>
    )
}

export const Costo = () => {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign='center'
            padding={isDesktop ? '2em 5em' : '2em 1em'}
            gap={2}
            marginTop='2em'
        >
            <Typography
                variant="h5"
                fontSize={isDesktop ? '1.5em' : '1.2em'}
                fontWeight={800}
                sx={{ color: colors.gray1 }}
            >
                PRECIO
            </Typography>
            <Typography
                variant="h3"
                fontSize={isDesktop ? '3em' : '2em'}
                fontWeight={600}
                sx={{ color: colors.primaryDark }}
                paddingBottom='1em'
            >
                Todas las funcionalidades por tan solo $25999 + IVA
            </Typography>
            <Grid
                container
                spacing={4}
            >
                <Grid item xs={12} sm={6} order={{xs: 1, sm: 2}}>
                    <img
                        src={Costo1}
                        alt="Paella dish"
                        width={isDesktop ? '90%' : '100%'}
                    />
                </Grid>
                <Grid item xs={12} sm={6} order={{xs: 2, sm: 1}}>
                    <Box 
                        display='flex'
                        flexDirection='column'
                        gap={6}
                        paddingTop={isDesktop ? '2em' : '0'}
                    >
                        <CostoFeature
                            title="Queremos verte crecer"
                            description="Accede a todas las funcionalidades de WhatsPro y dales a tus clientes la mejor atención por el mejor precio"
                            icon={heart}
                        />
                        <CostoFeature
                            title="Prueba gratuita de 30 días"
                            description="Accedé a todas las funcionalidades de Whatspro para ver de primera mano los resultados"
                            icon={money}
                        />
                        <CostoFeature
                            title="Atención 24/7"
                            description="Te damos atención personalizada a toda hora para acompañar tu crecimiento de la mejor manera"
                            icon={people}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
