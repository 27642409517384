import { Box, ImageList, ImageListItem, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import { giftyColors } from 'src/theme/colors';
import Img1 from 'src/assets/images/gifty/gallery/1.png';
import Img2 from 'src/assets/images/gifty/gallery/2.png';
import Img4 from 'src/assets/images/gifty/gallery/10.png';
import Img5 from 'src/assets/images/gifty/gallery/3.png';
import Img8 from 'src/assets/images/gifty/gallery/7.png';
import Img10 from 'src/assets/images/gifty/gallery/6.png';

const colors = giftyColors;

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

export const Masonry = () => {

    const isDesktop = useMediaQuery('(min-width:1024px)');

    return (
        <Box marginBottom={'2em'} marginTop={'4em'}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign='center'
                gap={2}
            >
                <Typography
                    variant="h5"
                    fontSize={isDesktop ? '22px' : '16px'}
                    fontWeight={700}
                    color={colors.gray1}
                    gutterBottom
                >
                    COMUNIDAD
                </Typography>
                <Typography
                    variant="h2"
                    fontSize={isDesktop ? '50px' : '26px'}
                    fontWeight={600}
                    color={colors.primaryDark}
                    paddingBottom={'0.5em'}
                >
                    Marcamos la diferencia
                </Typography>
            </Box>
            <Box>
                <ImageList
                    sx={{ width: '100%', padding: isDesktop ? '1em' : '0.3em' }}
                    variant="quilted"
                    cols={3}
                    rowHeight={40}
                    gap={isDesktop ? 40 : 7}
                >
                    {itemData.map((item) => (
                        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                            <img
                                {...srcset(item.img, 121, item.rows, item.cols)}
                                alt={item.title}
                                loading="lazy"
                                style={{
                                    borderRadius: '15px',
                                }}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        </Box>
    )
}

const itemData = [
    {
        img: Img1,
        title: 'Breakfast',
        rows: 4,
        cols: 1,
    },
    {
        img: Img5,
        title: 'Tasty Dinner',
        rows: 6,
        cols: 1,
    },
    {
        img: Img2,
        title: 'Tasty Dinner',
        rows: 4,
        cols: 1,
    },
    {
        img: Img4,
        title: 'Tasty Dinner',
        rows: 8,
        cols: 1,
    },
    {
        img: Img8,
        title: 'Tasty Dinner',
        rows: 8,
        cols: 1,
    },
    {
        img: Img10,
        title: 'Tasty Dinner',
        rows: 6,
        cols: 1,
    },
    
];
