import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { giftyColors } from 'src/theme/colors';

const colors = giftyColors;

const RendimientoFeature = ({title, value}) =>{

    const isDesktop = useMediaQuery('(min-width:1024px)');

    const isMobile = useMediaQuery('(min-width:700px)');

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign='center'
            padding={isDesktop ? '1.8em' : '1em'}
            gap={isDesktop ? 4 : 2}
            width={isMobile ? '25em' : '90vw'}
            sx={{
                boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                backgroundColor: colors.gray3,
                borderRadius: '8px',
            }}        >
            <Typography
                variant="h5"
                fontSize={isDesktop ? '20px' : '18px'}
                fontWeight={400}
                color={colors.primaryDark}
                width={isDesktop ? '75%' : '85%'}
            >
                {title}
            </Typography>
            <Typography
                variant="h3"
                fontSize={isDesktop ? '40px' : '24px'}
                fontWeight={600}
                color={colors.secondary}
            >
                {value}
            </Typography>
        </Box>
    )
}

export const Rendimiento = () => {

    const states = [
        { comensales: 10, ganancias: '30.000', cantidadReservas: '10%', roi: '200%'},
        { comensales: 25, ganancias: '80.000', cantidadReservas: '12%', roi: '230%'},
        { comensales: 50, ganancias: '150.000', cantidadReservas: '13%', roi: '300%'},
        { comensales: 100, ganancias: '300.000', cantidadReservas: '16%', roi: '400%'},
        { comensales: 200, ganancias: '600.000', cantidadReservas: '14%', roi: '500%'},
        { comensales: 500, ganancias: '1.500.000', cantidadReservas: '15%', roi: '600%'},

    ]

    const isDesktop = useMediaQuery('(min-width:1024px)');
    const [index, setIndex] = React.useState(0);

    const { comensales, ganancias, cantidadReservas, roi } = states[index];

    const handleChange = (e) =>{
        setIndex(e.target.value)
    }

  return (
    <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign='center'
        padding={'2em 1em'}
        sx={{ color: colors.primaryDark }}
        gap={4}
        marginBottom={'2em'}
        marginTop={'2em'}
    >
        <Typography
            variant="h3"
            fontSize={isDesktop ? '40px' : '22px'}
            fontWeight={600}
        >
            Descubrí qué rendimiento tienen nuestros clientes
        </Typography>
        <Box
            display="flex"
            alignItems={'center'}
            gap={2}
        >
            <Typography
                variant="h3"
                fontSize={isDesktop ? '35px' : '20px'}
                fontWeight={500}
            >
                Número de Gifty's vendidas 
            </Typography>
            <select 
                style={{
                    color: colors.secondary, 
                    border: 'none', 
                    borderBottom: `1px solid ${colors.secondary}`,
                    fontSize: isDesktop ? '25px' : '20px',
                    fontWeight: 500,
                    cursor: 'pointer',
                    background: colors.white,
                }}
                onChange={handleChange}  
                value={index}  
            >
                <option value={0}>10</option>
                <option value={1}>25</option>
                <option value={2}>50</option>
                <option value={3}>100</option>
                <option value={4}>200</option>
                <option value={5}>500</option>
            </select>
        </Box>
        <Box
            display="flex"
            alignItems={'center'}
            gap={2}
            flexDirection={isDesktop ? 'row' : 'column'}
        >
                <RendimientoFeature 
                    title='Facturación Extra'
                    value={`$${ganancias}`}
                />
                <RendimientoFeature
                    title='Conversión promedio'
                    value={`+${cantidadReservas}`}
                />
                <RendimientoFeature
                    title='ROI'
                    value={`${roi}`}
                />
        </Box>
    </Box>
  )
}
