import React, { useEffect } from "react";
import Carousel from 'react-multi-carousel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useMediaQuery } from "@material-ui/core";
import 'react-multi-carousel/lib/styles.css';
// import './styles.css'
import TestimonyCard from "./TestimonyCard";
import Testimonial1 from "src/assets/images/crossup/testimonials/testimonial1.png";
import Testimonial2 from "src/assets/images/crossup/testimonials/testimonial2.png";
import Testimonial3 from "src/assets/images/crossup/testimonials/testimonial3.png";
import Testimonial4 from "src/assets/images/crossup/testimonials/testimonial4.png";
import Testimonial5 from "src/assets/images/crossup/testimonials/testimonial5.png";

import { colors } from "src/theme/colors";


const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
    const { currentSlide, totalItems } = carouselState;
    const isDesktop = useMediaQuery('(min-width:1024px)');
    return (
      <div className="custom-button-group">
        {currentSlide !== 0
          ? <ArrowBackIcon onClick={() => previous()} fontSize='large' sx={{ cursor: 'pointer', fontSize: isDesktop && '3em', color: colors.primary }} />
          : <ArrowBackIcon onClick={() => previous()} fontSize='large' sx={{ color: colors.gray1, fontSize: isDesktop && '3em' }} />
        }
        {currentSlide !== totalItems - 1
          ? <ArrowForwardIcon onClick={() => next()} fontSize='large' sx={{ cursor: 'pointer', fontSize: isDesktop && '3em', color: colors.primary }} />
          : <ArrowForwardIcon onClick={() => next()} fontSize='large' sx={{ color: colors.gray1, fontSize: isDesktop && '3em' }} />
        }
      </div>
    );
  };

  
export const CarouselMobile = () => {
    const isDesktop = useMediaQuery('(min-width:1024px)');

    return (
        <div
            style={{
                position: 'relative',
                marginBottom: '2em',
                padding: isDesktop ? '0em 0vw 50px 2vw' : '0em 0em 50px 0em',
                width: '100%',
            }}
        >
            <Carousel
                arrows={false}
                centerMode={false}
                autoPlaySpeed={70000000}
                shouldResetAutoplay={false}
                partialVisible={true}
                customButtonGroup={<CustomButtonGroup />}
                dotListClass="dot-testimonials"
                draggable={!isDesktop}
                focusOnSelect={isDesktop}
                infinite={true}
                // keyBoardControl
                minimumTouchDrag={5}
                renderButtonGroupOutside={true}
                renderDotsOutside={true}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 3,
                        partialVisibilityGutter: 20
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 50
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 35
                    }
                }}
                showDots={true}
                slidesToSlide={1}
                swipeable={!isDesktop}
            >
                <TestimonyCard
                    image={Testimonial1}
                    name="GABRIELA"
                    partnerName="Aoni Designe"
                    testimonial='"Un antes y un despues luego de instalar Flowy. Super recomendable si tu intencion es aumentar el ticket promedio."'
                />
                <TestimonyCard
                    image={Testimonial2}
                    name="JULIO"
                    partnerName="Cestlavie"
                    testimonial='"Una bomba, ofreciendo productos cuando el cliente inicia la compra logre mas de 100 ventas extra en el primer mes."'
                />
                <TestimonyCard
                    image={Testimonial3}
                    name="SUSANA"
                    partnerName="Naranja accesorios"
                    testimonial='"Me gusto mucho, interfaz amigable y fácil de usar. Excelente para ofrecer productos más caros de lo que el cliente tenía pensado llevar."'
                />
                <TestimonyCard
                    image={Testimonial4}
                    name="AGUSTÍN"
                    partnerName="Vitalis Navitas"
                    testimonial='"Excelente, en los primeros 15 días aumenté un 20% mi facturación. La interfaz super amigable y el soporte impecable, la super recomiendo."'
                />
                <TestimonyCard
                    image={Testimonial5}
                    name="JOSEFINA"
                    partnerName="AMBAR ILUMINACIÓN"
                    testimonial='"¡¡Realmente funciona!! Aumenta el ticket de venta y ves rápido el retorno. ¡¡¡100% recomendado!!!."'
                />

            </Carousel>
        </div>
    )
}
