import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import './styles.css'

// import required modules
import { Navigation } from "swiper";

import Testimonial1 from "src/assets/images/crossup/testimonials/testimonial1.png";
import Testimonial2 from "src/assets/images/crossup/testimonials/testimonial2.png";
import Testimonial3 from "src/assets/images/crossup/testimonials/testimonial3.png";
import Testimonial4 from "src/assets/images/crossup/testimonials/testimonial4.png";
import Testimonial5 from "src/assets/images/crossup/testimonials/testimonial5.png";
import TestimonyCard from "./TestimonyCard";

export default function CarouselDesktop() {
    return (
        <>
            <Swiper
                slidesPerView={3}
                spaceBetween={60}
                centeredSlides={true}
                navigation={true}
                grabCursor={true}
                loop={true}
                initialSlide={1}
                modules={[Navigation]}
                className="swiper-testimonials"
                slideToClickedSlide={true}
                speed={600}
                breakpoints={{
                    900: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                      },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                    1100:{
                        slidesPerView: 3,
                        spaceBetween: 60,
                    },
                    1350:{
                        slidesPerView: 3,
                        spaceBetween: 60,
                    }
                  }}
            >
                <SwiperSlide>
                <TestimonyCard
                    image={Testimonial1}
                    name="GABRIELA"
                    partnerName="Aoni Designe"
                    testimonial='“Con gifty modernize el envío de gift cards, simplificar la gestión, mejorar la perspectiva que el cliente tiene de la marca y hacer crecer el negocio de las gift cards. ¡Los felicito!”'
                />
                </SwiperSlide>
                <SwiperSlide>
                <TestimonyCard
                    image={Testimonial2}
                    name="JULIO"
                    partnerName="Cestlavie"
                    testimonial='“¡Inmejorable! Siempre quise agregar giftcards a mi tienda ya que las mejores tiendas en el mundo lo hacen pero no había forma hasta que llegó gifty”'
                />
                </SwiperSlide>
                <SwiperSlide>
                <TestimonyCard
                    image={Testimonial3}
                    name="SUSANA"
                    partnerName="Naranja accesorios"
                    testimonial='“La experiencia de compra de mis clientes es excelente, apenas terminan de comprar la giftcard reciben un mail con el cupón, un 10!”'
                />
                </SwiperSlide>
                <SwiperSlide>
                <TestimonyCard
                    image={Testimonial4}
                    name="AGUSTÍN"
                    partnerName="Vitalis Navitas"
                    testimonial='“La creación de Giftcards es muy intuitiva, hay templates para todas las ocasiones y para todos los gustos”'
                />
                </SwiperSlide>
                <SwiperSlide>
                <TestimonyCard
                    image={Testimonial5}
                    name="JOSEFINA"
                    partnerName="AMBAR ILUMINACIÓN"
                    testimonial='“Interpretaron perfectamente la necesidad de los dueños de tienda, antes de este complemento tener giftcards era un dolor de cabeza!”'
                />
                </SwiperSlide>
            </Swiper>
        </>
    );
}
