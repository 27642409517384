import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Brand1 from 'src/assets/images/crossup/brands/billa.png';
import Brand2 from 'src/assets/images/crossup/brands/elepant.png';
import Brand3 from 'src/assets/images/crossup/brands/mini.png';
import Brand4 from 'src/assets/images/crossup/brands/reef.png';
import Brand5 from 'src/assets/images/crossup/brands/skin.png';
import Brand6 from 'src/assets/images/crossup/brands/sox.png';
import Brand7 from 'src/assets/images/crossup/brands/vitalis.png';
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, FreeMode } from "swiper";
import { Box, Typography, useMediaQuery } from '@mui/material';
import { colors } from 'src/theme/colors';
import './styles.css'


const BrandLogo = ({ logo, name, width, margin }) => {
  return (<img src={logo} alt={name} width={width} style={{ margin }} />)
};


export const BrandsCarousel = () => {

  const isDesktop = useMediaQuery('(min-width:1024px)');


  return (
    <Box
      padding={isDesktop ? '5em 0em 0em 0em' : '0em 1em 0em 1em'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      textAlign="center"
      gap={1}
      marginBottom='2em'
    >
      <Typography
        variant='h4'
        fontWeight={600}
        color={colors.primaryDark}
        fontSize={isDesktop ? '30px' : '20px'}
        paddingBottom={4}
      >
        Más de 1000 tiendas confían en nosotros
      </Typography>
      <Swiper
        slidesPerView={2}
        spaceBetween={40}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[FreeMode, Autoplay]}
        freeMode={true}
        loop={true}
        breakpoints={{
          550: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          700: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          900: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
          1150: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
          1400: {
            slidesPerView: 7,
            spaceBetween: 20,
          },
        }}
        className="swiper-container"
      >
        <SwiperSlide><BrandLogo logo={Brand5} name={'SkinFree'} width={200} /></SwiperSlide>
        <SwiperSlide><BrandLogo logo={Brand1} name={'Billabong'} width={90} /></SwiperSlide>
        <SwiperSlide><BrandLogo logo={Brand6} name={'SOX'} width={160} /></SwiperSlide>
        <SwiperSlide><BrandLogo logo={Brand4} name={'Reef'} width={140} /></SwiperSlide>
        <SwiperSlide><BrandLogo logo={Brand7} name={'Vitalis Navitas'} width={200} /></SwiperSlide>
        <SwiperSlide><BrandLogo logo={Brand2} name={'Elepants'} width={120} /></SwiperSlide>
        <SwiperSlide><BrandLogo logo={Brand3} name={'Mini Anima'} width={120} /></SwiperSlide>
      </Swiper>
    </Box>
  )
}
