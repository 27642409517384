import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'src/index.css';
import 'nprogress/nprogress.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
  </HelmetProvider>,
);
