import React from 'react';
import Typography from '@mui/material/Typography';
import { colors } from 'src/theme/colors';
import { Box, useMediaQuery } from '@mui/material';


export default function TestimonyCard({image, name, partnerName, testimonial}) {

    const isDesktop = useMediaQuery('(min-width:1024px)');


    return (
        <Box
            padding={1}
            display="flex"
            flexDirection="column"
            gap={1}
            width={isDesktop ? '100%' : '100%'}
        >
            {/* <img
                width='auto'
                src={image}
                alt="Paella dish"
                style={{ 
                        borderRadius: '15px', 
                        alignSelf: 'center',
                        height: isDesktop ? '400px' : '300px',
                }}
            /> */}
            <Box
                padding={2}
                display="flex"
                flexDirection="column"
                gap={isDesktop ? 2 : 1}
                className="testimony-card-text"
            >
                <Box>
                    <Typography
                        variant="h3"
                        fontSize={isDesktop ?  '20px' : '15px'}
                        fontWeight={600}
                        color={colors.primaryDark}
                        lineHeight={1.5}
                        sx={{textAlign: 'justify', textJustify: 'inter-word'}}
                    >
                        {testimonial}
                    </Typography>
                </Box>
                <Box
                    textAlign={'left'}
                >
                    <Typography
                        variant="h5"
                        fontSize={isDesktop ? '22px' : '15px'}
                        fontWeight={700}
                        color={colors.primaryDark}
                        lineHeight={1.5}
                    >
                        {name?.toUpperCase()}
                    </Typography>
                    <Typography
                        variant="h5"
                        fontSize={isDesktop ?  '20px' : '13px'}
                        fontWeight={400}
                        color={colors.gray1}
                        lineHeight={1.5}
                    >
                        {partnerName}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
