import { Box, useMediaQuery } from '@mui/material'
import { styled } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import NavBar from 'src/components/NavBar';
import { whatsproColors } from 'src/theme/colors';
import { Beneficios } from './Beneficios';
import { BrandsCarousel } from './BrandsCarrousel';
import { Contacto } from './Contacto';
import { Costo } from './Costo';
import Hero from './Hero';
import { Masonry } from './Masonry';
import { PorqueElegirnos } from './PorqueElegirnos';
import { Rendimiento } from './Rendimiento';
import { Testimonials } from './Testimonials';
import GiftyOG from 'src/assets/OG/gifty.jpeg';
import { useScript } from 'src/hooks/useScript';
import { useParams } from 'react-router-dom';
import { mapPlatformToInfo } from 'src/helpers/mapPlatformToData';

const colors = whatsproColors;


const OverviewWrapper = styled(Box)(
  () => `
    overflow: auto;
    background: ${colors.white};
    flex: 1;
    overflow-x: hidden;
    min-height: 100vh;
`
);

export const WhatsPRO = () => {
  const { t } = useTranslation();
  const [scroll, setScroll] = useState(0)
  const ticking = useRef(false);

  useEffect(() => {
    document.addEventListener('scroll', updateProgressBar)
    updateProgressBar()
    return () => document.removeEventListener('scroll', updateProgressBar)
  }, [])

  const updateProgressBar = () => {
    if (!ticking.current) {
      const windowHeight =
        document.documentElement.offsetHeight - window.innerHeight

      window.requestAnimationFrame(() => {
        setScroll(Math.floor((window.pageYOffset / windowHeight) * 100))
        ticking.current = false
      })
    }
    ticking.current = true
  }

  const matchQuery = useMediaQuery('(min-width:650px)');
  const status = useScript("https://whatspro-yvkptqz7ga-rj.a.run.app/api/script/particular/onLoad.js?store=878as38gc", matchQuery);

  const params = useParams();
  const [platform, setPlatform] = useState(params.platform || 'particular');

  const [validPlatform, setValidPlatform] = useState(false);

  useEffect(() => {
      if(Object.keys(mapPlatformToInfo).includes(platform) && platform !== 'particular') {
          setValidPlatform(true);
      }
  }, [platform])

  return (
    <NavBar screen='whatspro' platform={platform} validPlatform={validPlatform}>
      <Helmet>
        <title>{t('Flowy - WhatsPRO')}</title>
        {/*SEO TAGS OG*/}
        <meta property="og:title" content={t('Flowy - WhatsPRO')} />
        <meta property="og:description" content={t('WhatsPRO - Tu asistente virtual')} />
        <meta property="og:image" content={GiftyOG} />
        <meta property="og:url" content='https://flowypartners.com/whatspro' />
        <meta property="og:type" content="website" />
        {/*SEO TAGS TWITTER*/}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@flowy" />
        <meta name="twitter:creator" content="@flowy" />
        <meta name="twitter:title" content={t('Flowy - WhatsPRO')} />
        <meta name="twitter:description" content={t('WhatsPRO - Tu asistente virtual')} />
        <meta property="twitter:image" content={GiftyOG} />
        <meta property="twitter:url" content='https://flowypartners.com/whatspro' />
      </Helmet>
      <OverviewWrapper>
        <div
          style={{
            position: 'fixed',
            left: 0,
            right: 0,
            height: 5,
            zIndex: 99999,
            top: 0,
            bottom: 'unset',
            background: `linear-gradient(to right, ${colors.secondary} ${scroll / 2}%,${colors.secondary} ${scroll}%, transparent 0)`
          }}
        />
        <Hero platform={platform} validPlatform={validPlatform}/>
        <BrandsCarousel />
        <Testimonials />
        <Beneficios />
        <Costo />
        <Rendimiento />
        <PorqueElegirnos />
        <Masonry />
        <Contacto platform={platform} validPlatform={validPlatform}/>
      </OverviewWrapper>
    </NavBar >
  )
}
