

import React from 'react'

export const Redirect = ({ url }) => {

    React.useEffect(() => {
        window
            .location
            .replace(url)
    }
        , [url])
    return (
        <div>Redirecting...</div>
    )
}
