import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import './styles.css'

// import required modules
import { Navigation } from "swiper";

import Testimonial1 from "src/assets/images/crossup/testimonials/testimonial1.png";
import Testimonial2 from "src/assets/images/crossup/testimonials/testimonial2.png";
import Testimonial3 from "src/assets/images/crossup/testimonials/testimonial3.png";
import Testimonial4 from "src/assets/images/crossup/testimonials/testimonial4.png";
import Testimonial5 from "src/assets/images/crossup/testimonials/testimonial5.png";
import TestimonyCard from "./TestimonyCard";

export default function CarouselDesktop() {
    return (
        <>
            <Swiper
                slidesPerView={3}
                spaceBetween={60}
                centeredSlides={true}
                navigation={true}
                grabCursor={true}
                loop={true}
                initialSlide={1}
                modules={[Navigation]}
                className="swiper-testimonials"
                slideToClickedSlide={true}
                speed={600}
                breakpoints={{
                    900: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                      },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                    1100:{
                        slidesPerView: 3,
                        spaceBetween: 60,
                    },
                    1350:{
                        slidesPerView: 3,
                        spaceBetween: 60,
                    }
                  }}
            >
                <SwiperSlide>
                <TestimonyCard
                    image={Testimonial1}
                    name="GABRIELA"
                    partnerName="Aoni Designe"
                    testimonial='“La combinación perfecta entre simplicidad y efectividad. Los resultados son inmediatos con un setup de minutos”'
                />
                </SwiperSlide>
                <SwiperSlide>
                <TestimonyCard
                    image={Testimonial2}
                    name="JULIO"
                    partnerName="Cestlavie"
                    testimonial='“En mi tienda tengo muchos canales de ventas y este sistema me permite crearle un perfil a cada asesor para evitar pérdidas de tiempo”'
                />
                </SwiperSlide>
                <SwiperSlide>
                <TestimonyCard
                    image={Testimonial3}
                    name="SUSANA"
                    partnerName="Naranja accesorios"
                    testimonial='“Logré que mis clientes no salgan de la tienda para que me hagan siempre las mismas preguntas y finalicen su compra”'
                />
                </SwiperSlide>
                <SwiperSlide>
                <TestimonyCard
                    image={Testimonial4}
                    name="AGUSTÍN"
                    partnerName="Vitalis Navitas"
                    testimonial='“El chat es super personalizable y adaptable a la estética de mi tienda. Muy fácil de usar para el merchant y para el cliente”'
                />
                </SwiperSlide>
                <SwiperSlide>
                <TestimonyCard
                    image={Testimonial5}
                    name="JOSEFINA"
                    partnerName="AMBAR ILUMINACIÓN"
                    testimonial='“Las consultas bajaron un 40% en tan solo un mes y los resultados fueron excelentes.”'
                />
                </SwiperSlide>
            </Swiper>
        </>
    );
}
